* {
  font-family: 'Playfair', serif;
}

::-webkit-scrollbar {
  height: 8px;
  width: 10px;
  
}

::-webkit-scrollbar-track {
background: #1e1e1e;
display: none;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 12px;
  display: none;
}

.media-container {

}

.tabs {
  display: flex;
  gap: 30px;
}

.tab { color: #858585; transition: .3s color}
.tab:hover {cursor: pointer;}
.tab.active {color: #fff;}


.LazyLoad {
  height: auto!important;
  width: auto!important;
}

.media-src {display: none}

.media-container {
  opacity: 0;
  width: "100%";
  height: "0";
  padding-bottom: "0";
  position: relative;
  transition: .5s opacity linear;
}

.media-container.loaded {
  opacity: 1;
  padding-bottom: 100%;
}
.media-container.loaded .media-src {
  display: block;
}

body {
  background: #080808;
  color: #fff;
}

html{overflow-y:scroll}
.gallery {display: flex; column-gap: 30px;flex-wrap:wrap}
.gallery-col {flex: 220px}
.loader {background: #0a0a0a; width: 100%; height: 0; padding-bottom: 100%;}
.media:hover {cursor: pointer;}
.media {margin-bottom: 20px;}
.media-title {margin-top: 14px; text-align: center; line-height: 16px; font-size: 16px;}
.page {width: 100%; transition: .3s opacity ease-in-out; opacity: 0; display: none}
.pages {overflow: hidden}
.name {font-size: 36px; margin: 0;font-weight: 400}
.content-container {display: flex; justify-content: center; margin-top: 120px}
.content {width: 40vw}
#media-fullscreen { display: none; align-items:center;justify-content: center; position: fixed; z-index: 100; top: 0; left: 0; background: #000000d9; height: 100vh; width: 100vw}
#video-fullscreen, #image-fullscreen {display: none; max-height: 92vh; max-width: 92vw;}
.media-src {width: 100%; height: 100%; position: absolute; object-fit: cover;}
.contact-method {display: flex; align-items: center; gap: 10px;margin-bottom:10px}
.about-overlay {position: absolute; top: 0; left: 0; height: 100%; width: 100%; background: #000000b8; padding: 30px; box-sizing: border-box;}
.about-text {width: 60%;font-size:15px}
.about {position: relative; min-height: 45vh;}
.about-img {width: 100%; height: 100%; position: absolute; display: block; object-fit: cover;}
.contact-handle {color: #858585}

@media only screen and (max-width: 1200px) {
  .content {width: 60vw}
}

@media only screen and (max-width: 800px) {
  .content {width: 90vw}
  .about-text {width: 80%}
  .about {min-height: 60vh;}
}